import type { ClaimsContactInformation } from '@orus.eu/backend/src/services/claim-contact-service'
import type { OrusSessionUser } from '@orus.eu/backend/src/services/session/session-types'
import { formatDdMmYyyy } from '@orus.eu/calendar-date'
import { TechnicalError } from '@orus.eu/error'
import type { AvatarDecorativeWithIconProps, AvatarDecorativeWithIllustrationProps } from '@orus.eu/pharaoh'
import type { Language } from '@orus.eu/translations'
import { trpc } from '../../../client'
import { isProductionEnvironment } from '../../../lib/env-utils'
import { openTallyPopup } from '../../../lib/tally-util'

export const avatarsConfigurations: {
  [k in ClaimsContactInformation['type']]: {
    avatar: Required<Pick<AvatarDecorativeWithIconProps, 'icon' | 'backgroundColor'>>
    illustration: Required<Pick<AvatarDecorativeWithIllustrationProps, 'illustration'>>
  }
} = {
  decennialLiability: {
    avatar: { icon: 'shovel-light', backgroundColor: 'periwinkle' },
    illustration: { illustration: 'rcd_small' },
  },
  healthCoverage: {
    avatar: { icon: 'stethoscope-light', backgroundColor: 'peach' },
    illustration: { illustration: 'mutuelle_small' },
  },
  publicLiability: {
    avatar: { icon: 'book-open-cover-light', backgroundColor: 'sky' },
    illustration: { illustration: 'rcp_small' },
  },
  legalProtection: {
    avatar: { icon: 'scale-balanced-light', backgroundColor: 'peach' },
    illustration: { illustration: 'pj_small' },
  },
  assistance: {
    avatar: { icon: 'bell-concierge-light', backgroundColor: 'mindaro' },
    illustration: { illustration: 'premium_big' },
  },
  multiriskCoverage: {
    avatar: { icon: 'warehouse-light', backgroundColor: 'jasmine' },
    illustration: { illustration: 'mrp_small' },
  },
}

export const getReportClaimTallyId = (
  type: ClaimsContactInformation['type'],
  language: Language,
): string | undefined => {
  switch (type) {
    case 'assistance':
    case 'multiriskCoverage':
      if (language === 'fr') return 'mKPzqz'
      throw new Error('Not implemented')
    case 'legalProtection':
    case 'publicLiability':
      if (language === 'es') return 'w41B4r'
      if (language === 'fr') return 'm61eJN'
      throw new Error('Not implemented')
    case 'decennialLiability':
      if (language === 'fr') return 'wMrDK8'
      throw new Error('Not implemented')
    case 'healthCoverage':
      return undefined
  }
}

export const onOpenReportClaimTally = async (
  type: ClaimsContactInformation['type'],
  subscriptionId: string,
  user: OrusSessionUser | null,
): Promise<void> => {
  if (!user) {
    throw new TechnicalError('User is required to open the Tally popup')
  }

  const [contract] = await trpc.contracts.getMyContract.query(subscriptionId)

  const formId = getReportClaimTallyId(type, contract.operatingZone)

  if (!formId) {
    throw new Error('FormId is required to open the Tally popup')
  }

  openTallyPopup(formId, {
    layout: 'modal',
    hiddenFields: {
      isProductionEnvironment: isProductionEnvironment(),
      userId: user.id,
      emailAddress: contract.email,
      phone: contract.phone,
      firstName: user.firstName,
      lastName: user.lastName,
      contractNumber: contract.contractNumber,
      subscriptionId: contract.subscriptionId,
      companyName: contract.companyName,
      companyAddress: contract.companyAddress,
      startDate: formatDdMmYyyy(contract.startDate),
      activityName: contract.activity.displayName,
      options: contract.productsInformation.map((product) => product.product).join('\n'),
      activitiesList: contract.activitiesList.map((activity) => `- ${activity.displayName}`).join('\n'),
      customRcphActivity: contract.customRcphActivity ?? '',
      hasMrpwAssistance: contract.hasMrpwAssistance,
    },
  })
}

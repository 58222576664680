import { css } from '@emotion/react'
import styled from '@emotion/styled'
import type {
  ClaimsContactInformation,
  ContractClaimsContactInformations,
} from '@orus.eu/backend/src/services/claim-contact-service'
import {
  AvatarDecorative,
  borderRadius,
  colorTokens,
  desktopMediaQuery,
  Dialog,
  RowButtonV2,
  RowContainerV2,
  spacing,
  Text,
  useAsyncCallback,
  useTranslate,
  useUiContext,
} from '@orus.eu/pharaoh'
import { memo, type FunctionComponent } from 'react'
import { useSession } from '../../../../lib/session'
import { useShowIllustrations } from '../../../../lib/use-show-illustrations'
import { SmallScreenMargin } from '../../../templates/small-screen-margin'
import { avatarsConfigurations, onOpenReportClaimTally } from '../report-claim-util'
type ReportClaimsDialogProps = {
  contractsClaimsContactInformations: ContractClaimsContactInformations[]
  onClose: () => void
}

export const ReportClaimsDialog: FunctionComponent<ReportClaimsDialogProps> = memo(function ReportClaimsDialog({
  contractsClaimsContactInformations,
  onClose,
}) {
  const screenType = useUiContext()
  const translate = useTranslate()
  const { user } = useSession()
  const showIllustrations = useShowIllustrations()

  const onClaimContractClick = useAsyncCallback(
    async (type: ClaimsContactInformation['type'], subscriptionId: string) => {
      onClose()
      await onOpenReportClaimTally(type, subscriptionId, user)
    },
    [onClose, user],
  )

  return (
    <Dialog
      title={translate('report_claim')}
      style="base"
      size="medium"
      withoutContentMargin={screenType === 'mobile'}
      onClose={onClose}
    >
      <Container>
        {contractsClaimsContactInformations.map((contractClaimsContactInformations, contratIndex) => (
          <ContractTitleContainer key={contratIndex}>
            <SmallScreenMargin>
              <Text variant="subtitle">{contractClaimsContactInformations.activity?.displayName}</Text>
              <Text
                variant="body2"
                color={colorTokens['color-text-base-basic']}
                css={css`
                  margin-top: ${spacing[20]};
                  margin-bottom: ${spacing[50]};
                `}
              >
                {contractClaimsContactInformations.address}
              </Text>
            </SmallScreenMargin>
            <ClaimsContactContainer variant="mobile">
              {contractClaimsContactInformations.claimsContactInformations
                ?.filter((claimContact) => claimContact.type !== 'assistance')
                .map((claimContact, index) => (
                  <RowButtonV2
                    key={claimContact.type + index}
                    avatarLeft={
                      <AvatarDecorative
                        size={showIllustrations ? '70' : '60'}
                        {...avatarsConfigurations[claimContact.type][showIllustrations ? 'illustration' : 'avatar']}
                      />
                    }
                    primaryText={claimContact.title}
                    onClick={() =>
                      onClaimContractClick(claimContact.type, contractClaimsContactInformations.subscriptionId)
                    }
                  />
                ))}
            </ClaimsContactContainer>
          </ContractTitleContainer>
        ))}
      </Container>
    </Dialog>
  )
})

const Container = styled.div`
  width: 100%;
  gap: ${spacing[70]};
  display: flex;
  flex-direction: column;
`

const ContractTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ClaimsContactContainer = styled(RowContainerV2)`
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  border: 0;
  border-top: 1px solid ${colorTokens['color-stroke-base']};
  border-bottom: 1px solid ${colorTokens['color-stroke-base']};

  ${desktopMediaQuery} {
    border-radius: ${borderRadius[20]};
    border: 1px solid ${colorTokens['color-stroke-base']};
  }
`
